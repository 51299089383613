.home-main-container {
  margin: 0;
  display: flex;
  flex-direction: column;
}

.home-container {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.home-hero-container {
  background-image: url("/public/imgs/home-bg-new.webp");
  background-size: cover;
  background-position: center;
  padding: 100px;
  text-align: center;
  color: #fff;
  overflow: hidden;
  width:100%;
  height:400px;
  border-radius: 35% 15%;
}

@media (max-width: 680px) {
  .home-hero-container {
    padding: 10px;
  }
}

.hero h2 {
  font-size: 24px;
  margin-bottom: 10px;
  text-shadow: -1px 1px 20px rgb(0 0 0);
}

.hero h1 {
  font-size: 48px;
  margin-bottom: 20px;
  text-shadow: -1px 1px 20px rgb(0 0 0);
}

.hero .book-button {
  background-color: #ffd700;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 18px;
}

.hero p {
  font-size: 16px;
}

.welcome-message {
  font-size: 1.4em;
  /* font-weight: 400; */
  /* line-height: 1.9; */
  padding: 20px 10px;
  margin: 10px 0;
  background-color: #fff;
}
