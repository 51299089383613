.hours-container {
  display: flex;
  flex-direction: column;

  .day-and-hours {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  display: flex;
  .days,
  .hours {
    /* padding: 10px; */
    /* border: 1px solid #f1f1f1; */
    /* border-radius: 5px; */
    margin-right: 10px;
  }

  .hours {
    font-weight: 400;
  }
}

.home-map-container {
  flex-grow: 1;

  iframe {
    border: 0px;
    height: 300px;
    width: 100%;
  }
}
